import React, { useEffect, useState } from 'react';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import Loader from '@components/loader/loader';

const NotFoundPage: React.FC = () => {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, [setMount]);

  if (!isMount) return <Loader />;

  return (
    <Layout>
      <SEO title="404: Not found" description="" />
      <section>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn't exist... the sadness.</p>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
